import React from 'react'

import { LoyaltyRedemption } from './LoyaltyRedemption/LoyaltyRedemption/LoyaltyRedemption'
import { CASH_REDEMPTION_TYPE, BIRTHDAY_REDEMPTION_TYPE } from '../constants'
import { LoyaltyConfig, PartyMember } from './types'

export const LoyaltySection = ({
  partyMember,
  partyGuid,
  loyaltyConfig,
  memberAuthToken,
  onLoyaltyUpdate,
  mode
}: {
  partyMember: PartyMember
  partyGuid: number
  loyaltyConfig: LoyaltyConfig
  memberAuthToken: string
  onLoyaltyUpdate: () => void
  mode: string
}) => {
  const availableRewards = partyMember?.availableRewards

  if (
    !availableRewards ||
    availableRewards?.length === 0 ||
    ('rewardType' in loyaltyConfig
      ? loyaltyConfig.rewardType !== 'CASHBACK' &&
        loyaltyConfig.rewardType !== 'BOTH'
      : false)
  ) {
    return null
  }

  const allRewards = partyMember?.availableRewards.concat(
    partyMember?.appliedRewards
  )

  const balanceMessage = partyMember?.loyaltyState?.messages?.balance

  const cashRedemption = allRewards.find(
    (redemption) => redemption.referenceId === CASH_REDEMPTION_TYPE
  )
  const birthdayRedemption = allRewards.find(
    (redemption) => redemption.referenceId === BIRTHDAY_REDEMPTION_TYPE
  )

  return (
    <div
      className='bg-white mx-3 mt-8 py-6 px-4 rounded-lg border-[1px] border-gray-50 border-solid'
      data-testid='loyalty-section-rewards-available'
    >
      <p className='pb-4 font-bold type-headline-5'>Rewards</p>
      <div className=' flex type-default text-gray-100 pb-4'>
        {balanceMessage}
      </div>
      {birthdayRedemption && (
        <LoyaltyRedemption
          partyMember={partyMember}
          partyGuid={partyGuid}
          memberAuthToken={memberAuthToken}
          redemption={birthdayRedemption}
          onLoyaltyUpdate={onLoyaltyUpdate}
          mode={mode}
        />
      )}
      {cashRedemption && (
        <LoyaltyRedemption
          partyMember={partyMember}
          partyGuid={partyGuid}
          redemption={cashRedemption}
          memberAuthToken={memberAuthToken}
          onLoyaltyUpdate={onLoyaltyUpdate}
          mode={mode}
        />
      )}
    </div>
  )
}
